import { SheetFormat } from '@teampetfriends/react-hooks';

import { getTokenData } from '@utils/token';

import {
  SHEET_물류비_배송비_다운로드_ADMIN,
  SHEET_물류비_배송비_다운로드_VENDOR,
  SHEET_물류비_보관비_다운로드_ADMIN,
  SHEET_물류비_보관비_다운로드_VENDOR,
} from './excelFormat/downloadLogisticsCostFormat';
import {
  SHEET_교환반품_공급가_다운로드_ADMIN,
  SHEET_교환반품_공급가_다운로드_VENDOR,
  SHEET_교환반품_배송비_다운로드_ADMIN,
  SHEET_교환반품_배송비_다운로드_VENDOR,
} from './excelFormat/downloadOrderChargeClaimFormat';
import {
  SHEET_결제대금_다운로드_ADMIN,
  SHEET_결제대금_다운로드_VENDOR,
} from './excelFormat/downloadOrderChargesFormat';
import {
  SHEET_쿠폰_정산_상세_다운로드_ADMIN,
  SHEET_쿠폰_정산_상세_다운로드_VENDOR,
} from './excelFormat/downloadSettlementCouponDetailFormat';
import type { ChargeType, SettlementStatementParams } from './interfaces';

export const getSettlementStatementInitialState = (): SettlementStatementParams => {
  const checkUser = getTokenData();

  const defaultYear = `${new Date().getFullYear()}`;
  const defaultMonth = `${new Date().getMonth() + 1}`;

  return {
    startYear: defaultYear,
    startMonth: defaultMonth,
    endYear: defaultYear,
    endMonth: defaultMonth,
    settlementCategory: '',
    statuses: null,
    issueType: '',
    searchCondition: checkUser?.is_vendor ? '' : 'VENDOR_NAME',
    searchContents: '',
    pageNumber: 1,
    pageSize: 20,
  };
};

export const SETTLEMENT_DATE_FORMAT = 'YYYY-MM-DD';

export const VALIDATION_MESSAGE = {
  SELECTED_ROW: '선택된 내역이 없습니다.',
  SETTLEMENT_EXPECTED: '정산예정 상태에서만 가능합니다.',
  SETTLEMENT_CONFIRMED: '정산확정 상태에서만 가능합니다.',
  REQUIRED: '필수값이 없습니다.',
};

export const SHEETS_MAP: Record<
  'ADMIN' | 'VENDOR',
  Record<ChargeType, { name: string; sheetFormat: SheetFormat[] }[]>
> = {
  ADMIN: {
    PRODUCT: [SHEET_결제대금_다운로드_ADMIN, SHEET_교환반품_공급가_다운로드_ADMIN],
    DISCOUNT: [SHEET_쿠폰_정산_상세_다운로드_ADMIN],
    CLAIM_SHIPPING: [SHEET_교환반품_배송비_다운로드_ADMIN],
    STORAGE: [SHEET_물류비_보관비_다운로드_ADMIN],
    SHIPPING_HANDLING: [SHEET_물류비_배송비_다운로드_ADMIN],
  },
  VENDOR: {
    PRODUCT: [SHEET_결제대금_다운로드_VENDOR, SHEET_교환반품_공급가_다운로드_VENDOR],
    DISCOUNT: [SHEET_쿠폰_정산_상세_다운로드_VENDOR],
    CLAIM_SHIPPING: [SHEET_교환반품_배송비_다운로드_VENDOR],
    STORAGE: [SHEET_물류비_보관비_다운로드_VENDOR],
    SHIPPING_HANDLING: [SHEET_물류비_배송비_다운로드_VENDOR],
  },
};

export const CUSTOM_SHEET_SLICE_POINT: Record<string, number> = {
  '반품 공급가': 1,
  '교환반품 배송비': 1,
  배송비: 1,
};

export const SHEET_ENTRY_POINT: Record<ChargeType, string[]> = {
  PRODUCT: ['주문 목록', '반품 공급가'],
  DISCOUNT: ['쿠폰정산상세'],
  CLAIM_SHIPPING: ['교환반품 배송비'],
  STORAGE: ['보관비'],
  SHIPPING_HANDLING: ['배송비'],
};

export const PAGE_SIZE_OPTIONS = [20, 40, 60, 80, 100];
